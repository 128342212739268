/* Sidebar general */
.sidebar {
  width: 30px;
  height: 21px;
  cursor: pointer;
  position: relative;
  z-index: 20;
}

.sidebar:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.line {
  width: 100%;
  height: 3px;
  background-color: #433B24;
  margin-bottom: 5px;
  transition: transform 0.3s ease;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.sidebar.open .line:first-child {
  transform: translateY(8px) rotate(45deg);
}

.sidebar.open .line:nth-child(2) {
  opacity: 0;
}

.sidebar.open .line:last-child {
  transform: translateY(-8px) rotate(-45deg);
}

/* Sidebar background */
.sidebar-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 25vw; /* Ancho normal */
  background-color: #433B24;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  transition: transform 0.5s ease;
  transform: translateX(-100%);
  z-index: 10;
}

@media (max-width: 1023px) {
  .logo-container-Sidebar .logo {
    width: 40vw; /* Ajusta el tamaño del logo */
  }
  .sidebar-background {
    width: 50vw !important; /* Aumenta el ancho en pantallas pequeñas */
  }
  .sidebar-content {
    width: 50vw !important;
    height: 100%;
    font-family: 'Lato';
  }
  .submenu-item {
    display: block;
    color: rgba(255, 255, 255, 0.6);
    padding: 5px;
    text-align: left;
    text-decoration: none;
    margin-left: 1%;
    font-size: 4vw !important;
  }
  .menu-item,
  .sidebar-item,
  .experiencias-item {
    font-size: 5vw; /* Ajusta el tamaño de la fuente en pantallas pequeñas */
  }
}

.sidebar.open + .sidebar-background {
  transform: translateX(0);
}

/* Transiciones */
.sidebar-enter {
  opacity: 0;
}

.sidebar-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.sidebar-exit {
  transform: translateX(0);
}

.sidebar-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

/* Sidebar header */
.sidebar-header {
  margin-right: 10px;
  width: 25vw;
  margin-top: 5%;
}

.sidebar-content {
  width: 25vw;
  height: 100%;
  font-family: 'Lato';
}

/* Logo */
.logo-container-Sidebar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo-container-Sidebar .logo {
  width: 10vw;
  height: auto;
}


.logo-container-Sidebar .logo:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

/* Sidebar body */
.sidebar-body {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100vw;
}

.menu-item,
.sidebar-item,
.experiencias-item {
  background-color: transparent;
  color: #F2F2F2;
  padding: 10px;
  text-align: left;
  font-size: 1.8vw;
  text-decoration: none;
}

@media (max-width: 1023px) {
  .menu-item,
  .sidebar-item,
  .experiencias-item {
    font-size: 5vw; /* Ajusta el tamaño de la fuente en pantallas pequeñas */
  }
}

/* Submenú */
.submenu {
  display: none;
  text-align: left;
  flex-direction: column;
}

.submenu-item {
  display: block;
  color: rgba(255, 255, 255, 0.6);
  padding: 5px;
  text-align: left;
  text-decoration: none;
  margin-left: 1%;
  font-size: 1.8vw;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
  margin-top: 0.5vh;
}

@media (max-width: 1023px) {
  .arrow-icon {
    width: 3vw;
    height: 3vh;
  }
}

/* Ajuste del contenido dentro del sidebar */
.sidebar-body {
  display: flex;
  flex-direction: column;
  width: 100%;       /* Asegura que ocupe solo el ancho del sidebar */
  overflow-x: hidden; /* Evita desbordes laterales */
  padding-top: 20px;
  box-sizing: border-box;
}

/* Ajuste para los ítems del menú */
.menu-item,
.sidebar-item,
.experiencias-content {
  display: block;
  width: 100%;         /* Ocupa solo el ancho del sidebar */
  padding: 10px 15px;
  box-sizing: border-box;
  overflow: hidden;     /* Evita que contenido interno se desborde */
}

.submenu-item {
  display: block;
  width: 100%;         /* Ocupa solo el ancho del sidebar */
  padding: 10px 10%;
  box-sizing: border-box;
  overflow: hidden;     /* Evita que contenido interno se desborde */
}

/* Hover mejorado, ahora limitado al sidebar */
.menu-item:hover,
.sidebar-item:hover,
.submenu-item:hover,
.experiencias-content:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFF;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
  padding-left: 25px;
  border-radius: 4px;
}
