@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
.slider-inicio {
  width: 100%; /* Ocupa todo el ancho de la pantalla por defecto */
  height: 100vh; /* Ocupa todo el alto de la pantalla por defecto */

  @media (min-width: 640px) { /* sm */
    width: 60%;
    height: 40vh;
  }

  @media (min-width: 768px) { /* md */
    width: 100%; /* Ocupa todo el ancho para pantallas medianas */
    height: 100vh; /* Ocupa todo el alto de la pantalla en pantallas medianas */
  }

  @media (min-width: 1024px) { /* lg */
    width: 100%; /* Ocupa todo el ancho para pantallas grandes */
    height: 100vh; /* Ocupa todo el alto de la pantalla en pantallas grandes */
  }
}


.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
}

.swiper-button-next {
  right: 2vw;
}

.swiper-button-prev {
  left: 2vw;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 2rem;
  color: white;
}

/* Media Query para pantallas pequeñas */
@media (max-width: 768px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.5rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    padding: 0.8rem;
  }
}

/* Media Query para pantallas muy pequeñas (por ejemplo, móviles) */
@media (max-width: 480px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.2rem;
  }

  .swiper-button-next {
    right: calc(8vw + 1rem);
  }

  .swiper-button-prev {
    left: calc(8vw + 1rem);
  }

  .text-white {
    font-size: 1.5rem; /* Texto aún más pequeño */
  }

  .Destacado {
    font-size: 2.5rem !important; /* Forzar el tamaño */
  }
}

.image-slider {
    background-color: #433B24;
    width: 100vw;
    position: relative;
}

.image-slider .Fondo {
    position: absolute;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    left: 0;
}

.imagen_6_Inicio {
    width: 100%;
    height: 100%;
    display: block;
}

.Slider-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(67, 59, 36, 0.74);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 4.5rem;
    /*cambio altura */
}

.Slider-overlay p {
    font-size: 1.8em;
    /*cambio tamaño de letra*/
    font-family: 'Lato';
    text-decoration: none;
}

.arrow-down {
    width: 45px;
    height: 45px;

}

.arrow-down:hover {
    transform: scale(1.4);
    transition: transform 0.3s ease-in-out;
}

.white-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 500px;
    height: auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.divBotonBuscar {
    width: 100%;
    /* Ajusta al ancho del contenedor padre */
    max-width: 400px;
    /* Máximo ancho del botón */
    height: 33px;
    background-color: #433B24;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;

}

@media (max-width: 768px) {
    .divBotonBuscar {
        width: 80%;
    }
}

.divBotonBuscar :hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}



.TextoExperiencias {
    font-family: 'Karla';
    font-size: 1.5em;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.TextoExperiencias .Destacado {
    font-family: 'Karla';
    font-size: 2.5em;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-weight: bold;
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 59, 36, 0.3);
    /* Color de la capa con opacidad */
}