.ButtonBuscar {
    background-color: transparent;
    color: white;
    width: 100vw;
    height: auto;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    /* Letra en negrita */
    font-family: "Lato";

}