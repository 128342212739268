.CabañasPage {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    background-color: #82673B;

}

.cuerpoCabañas {
    background-color: white;
    width: 100%;
    height: auto;

}

body {
    overflow-x: hidden;
}

.fixed-header-Cabañas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
}

.FooterCabañas {
    background-color: transparent;
    width: 100vw;
    height: 15vh; 
    
}

.ComponenteCabañas {
    background-color: white;
    width: 100%;
    height: auto; 
    display: flex;
    align-items: center;
    justify-content: center;
} 


@media (max-width: 800px) {
    .ComponenteCabañas {
      height: 75vh;
    }
  }