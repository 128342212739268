
/* Contenedor de la imagen tipo Cabaña */
.imageTipoContainer {
    width: 100%;
    height: 200px; /* Altura fija */
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
}

/* Asegura que el swiper se ajuste bien */
.cabaña-carousel {
    width: 100%; /* Asegura que ocupe todo el ancho */
    height: 100%; /* Asegura que ocupe toda la altura */
}

.swiper-slide img {
    width: 100% !important; /* Asegura que las imágenes ocupen todo el ancho */
    height: 100% !important; /* Asegura que las imágenes ocupen toda la altura */
    object-fit: cover; /* Mantiene proporciones y rellena */
}

/* Contenedor de tarjetas de tipo Cabaña */
.tiposCabañasRow {
    display: flex;
    flex-wrap: wrap; /* Permite que las tarjetas se ajusten a nuevas líneas si es necesario */
    justify-content: space-between; /* Espacio entre las tarjetas */
    margin-top: 2rem; /* Agrega margen superior al contenedor */
}

.tipoCabañaCard {
    width: 30%; /* En pantallas grandes y medianas, cada tarjeta ocupará un 30% del ancho */
    margin-top: 1rem; /* Agrega margen superior a cada tarjeta */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 810px) {
    .tiposCabañasRow {
        flex-direction: column; /* En pantallas pequeñas, las tarjetas se apilan una debajo de la otra */
        gap: 1.5rem; /* Espacio entre las tarjetas */
        align-items: center; /* Centra las tarjetas horizontalmente */
        margin-top: 1rem; /* Agrega margen superior al contenedor en pantallas pequeñas */
    }

    .tipoCabañaCard {
        width: 100%; /* En pantallas pequeñas, cada tarjeta ocupará todo el ancho disponible */
        margin-top: 1rem; /* Agrega margen superior a cada tarjeta */
    }

    .imageTipoContainer {
        height: 150px; /* Ajustar altura para pantallas más pequeñas */
    }
}

/* Ajustes para pantallas medianas */
@media (min-width: 768px) {
    .tiposCabañasRow {
        margin-left: 2rem; /* Agrega margen lateral izquierdo en pantallas medianas */
        margin-right: 2rem; /* Agrega margen lateral derecho en pantallas medianas */
    }

    .tipoCabañaCard {
        width: 30%; /* En pantallas medianas, cada tarjeta ocupa el 30% del ancho */
    }
}

/* Ajustes para pantallas grandes */
@media (min-width: 1024px) {
    .tiposCabañasRow {
        margin-left: 3rem; /* Agrega margen lateral izquierdo en pantallas grandes */
        margin-right: 3rem; /* Agrega margen lateral derecho en pantallas grandes */
    }

    .tipoCabañaCard {
        width: 30%; /* En pantallas grandes, cada tarjeta ocupa el 30% del ancho */
    }
}
