.contenedorExperiencias {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    background-color: #82673B;
}

.cuerpo-Experiencias {
    background-color: white;
    align-items: center;
    justify-content: center;


}

body {
    overflow-x: hidden;
}

.fixed-header-Expereincias {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    z-index: 5;

}

.EspacioBlanco {
    display: none;
    width: 100%;
    height: 80px;
    background-color: white;
}

.outer-div-Footer-Experiencias {
    background-color: transparent;
    width: 100vw;
    height: 15vh;

}

.DescripcionExperiencia {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    margin: 5%;
}

.DescripcionExperiencia p {
    font-size: 1em;
    font-family: 'Karla';
    text-align: center;
    color: black;
    width: 75vw;
}

.BotonVolver {
    width: 100vw;
    height: auto;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5%;
}

.Volver {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.4);
    font-size: 15px;
    font-family: 'Lato';
    justify-self: self-start;
}

.arrow-left {
    width: 15px;
    height: 15px;
    color: rgba(0, 0, 0, 0.4);


}

.BotonVolver a {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    padding-left: 5%;
    align-items: center;

}


.arrow-left :hover {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}

.TituloExperiencia {
    display: none;
    width: auto;
    height: auto;
    margin: 5%;
    align-items: center;
    justify-content: center;
}

.TituloExperiencia p {
    font-size: 2em;
    font-family: 'Karla';
    text-align: center;
    color: rgba(0, 0, 0, 1);

}

.ImagenPrincipalExperienciaObservatorio {
    width: 100%;
    height: auto;
    display: none;
    justify-content: center;
    align-items: center;
}

.ImagenPrincipalExperienciaObservatorio img {
    width: 90%;
    height: 33%;
}

.ImagenPrincipalExperienciaTours {
    width: 100%;
    height: auto;
    display: none;
    justify-content: center;
    align-items: center;
}

.ImagenPrincipalExperienciaTours img {
    width: 90%;
    height: 60%;
}

.PreciosObservatorio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 5%;

}

.PreciosObservatorio .contenedorImagen {
    width: 55%;
    height: auto;
    display: flex;
    justify-content: start;
    align-items: center;

}

.PreciosObservatorio .contenedorImagen img {
    width: 100%;
    height: 117%;
    padding-right: 3%;
    border-radius: 8%;
}

.PreciosObservatorio .Descripcion {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 3vh;

}

.PreciosObservatorio .Descripcion .Des2 {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;

}

.PreciosObservatorio .Descripcion .Des1 {
    padding-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;


}

.PreciosObservatorio .Descripcion .Des1 p {
    font-size: 1em;
    font-family: 'Karla';
    text-align: center;
    color: rgba(0, 0, 0, 0.8);

}

.PreciosObservatorio .Descripcion .Des2 p {
    font-size: 1em;
    font-family: 'Karla';
    text-align: center;
    color: rgba(0, 0, 0, 0.8);

}

.PreciosObservatorio .Descripcion .Titulo {
    font-size: 1.5em;
    font-family: 'Lato';
    text-align: center;
    color: black;

}

.FotoDes2 {
    display: none;
    width: 90%;
    height: auto;
    align-items: center;
    justify-content: center
}

.FotoDes2 img {
    width: 100%;
    height: 59%;
    border-radius: 8%;
}

.Tours {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: auto;
    margin-bottom: 5%;
    padding: 2%;

}

.Tours .contenedorImagenes1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    height: auto;


}

.Tours .contenedorImagenes2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    height: auto;


}

.Imagen1 {
    position: relative;
    width: 100%;
    height: auto;
}

.Imagen2 {
    position: relative;
    width: 100%;
    height: auto;
}

.Imagen1 img,
.Imagen2 img {
    width: 99%;
    height: 36%;
    margin-bottom: 10px;
}

.Imagen1 p,
.Imagen2 p {
    width: 80%;
    position: absolute;
    font-family: 'Karla';
    font-size: 1em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;

}

.Imagen2:hover {
    transform: scale(1.01);
    transition: transform 0.3s ease-in-out;
}

.Imagen1:hover {
    transform: scale(1.01);
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 1135px) {


    .BotonVolver {
        display: flex;
    }

    .EspacioBlanco {
        display: flex;
    }

    .TituloExperiencia {
        display: flex;
    }

    .ImagenPrincipalExperienciaObservatorio {
        display: flex;
    }

    .ImagenPrincipalExperienciaTours {
        display: flex;
    }

    .PreciosObservatorio {
        flex-direction: column;
        align-items: center;
    }

    .PreciosObservatorio .contenedorImagen {
        justify-content: center;
        width: 85%;

    }

    .PreciosObservatorio .contenedorImagen img {
        height: 67%;
    }

    .PreciosObservatorio .Descripcion .Des2 {
        align-items: center;
        text-align: center;

    }

    .PreciosObservatorio .Descripcion .Des1 {
        align-items: center;
        text-align: center
    }

    .FotoDes2 {
        display: flex;
        width: 80%;
    }

    .Tours {
        flex-direction: column;
    }

    .Tours .contenedorImagenes1,
    .Tours .contenedorImagenes2 {
        width: 100%;
    }

    .contenedorImagenes1 img,
    .contenedorImagenes2 img {
        width: 100%;
    }
}