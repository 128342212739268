.CondicionesGenerales {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    background-color: #F2F2F2;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.Agrupador {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    border-radius: 8px;
}

.CondicionesGenerales .Titulo1 {
    text-align: center;
    color: rgba(0, 0, 0, 5);
    font-family: "Lato";
    font-size: 20px;
    margin: 2%;
}


.CondicionesGenerales .Titulo {
    text-align: center;
    color: rgba(0, 0, 0, 5);
    font-family: "Lato";
    font-size: 20px;
    margin: 2%;
}

.CondicionesGenerales .Horas {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Karla";
    font-size: 15px;
    margin: 1%;
}

.CondicionesGenerales .Descripcion {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Karla";
    font-size: 15px;
    margin: 2%;
}

.CondicionesGenerales .Detalles {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Karla";
    font-size: 15px;

}

.CondicionesGenerales .DetallesFinal {
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Karla";
    font-size: 15px;
    margin-bottom: 4%;
}