.contenedorDescripcionCabañas {
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: white;

}

.cuerpoDescripcionCabañas {
    background-color: white;
}

body {
    overflow-x: hidden;
}

.EspacioBlanco {
    display: none;
    width: 100%;
    height: 80px;
    background-color: white;
}

.fixed-header-descriptionCabañas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
}

.outer-div-Footer-descriptionCabañas {
    background-color: transparent;
    width: 100vw;
    height: 15vh;

}

.Codiciones {
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

.button-container-Cabañas {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10vh;


}

.reserva-button-Cabañas {
    background-color: transparent;
    height: auto;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    /* Letra en negrita */
    font-family: "Lato";
    color: white;
    background-color: #433B24;
    opacity: 1;
    /* Ajusta este valor según tus necesidades */
    margin: 20px auto;
    padding: 4px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    width: 80%;

}

.reserva-button-Cabañas:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 1135px) {
    .EspacioBlanco {
        display: flex;
    }

}