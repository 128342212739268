.contenedorFormularioPago {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    background-color: #82673B;
}

.cuerpoFormularioPago {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}


body {
    overflow-x: hidden;
}

.fixed-header-FormularioPago {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    z-index: 5;
}

.ContenedorImagenFormulario {
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
}

.ContenedorImagenFormulario img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.formPayWrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}