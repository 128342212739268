.welcome-description {
    background-color: transparent;
    color: black;
    width: 100vw;
    height: auto;
    font-family: "Karla";
    display: flex;
    flex-direction: column;
    align-items: center;


}

.text-row {
    padding-top: 10px;

}

.column-welcome {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.Titulo-1-Inicio {
    height: auto;
    width: 100%;
    text-align: center;
    font-stretch: normal;
    font-style: Extralight;
    font-weight: 300;
    text-decoration: none;
    font-family: "Karla";
    font-size: 24px;
    line-height: normal;
    color: rgba(0, 0, 0, 0.5);
}

.Titulo-2-Inicio {
    width: 100%;
    height: auto;
    text-align: center;
    font-stretch: normal;
    font-style: Extralight;
    text-decoration: none;
    font-family: "Lato";
    font-size: 36px;
    line-height: normal;
    color: rgb(0, 0, 0);
    padding: 10px 0;
}

@media (max-width: 640px) {

    .Titulo-1-Inicio,
    .Titulo-2-Inicio {
        padding-left: 8%;
        padding-right: 8%;
    }
}

.Texto-Bienvenida {
    color: rgb(0, 0, 0, 0.7);
    width: 95vw;
    /* cambio de 100vw a 95vw para darle padding responsivo */
    height: auto;
    text-align: center;
    line-height: normal;
    font-family: "Karla";
    font-size: 18px;
    font-stretch: normal;
    font-style: Regular;
    text-decoration: none;
    text-wrap: wrap;
    /*wrap para evitar desborde del texto */

}

@media (max-width: 640px) {
    .Texto-Bienvenida {
        padding-left: 8%;
        padding-right: 8%;
        font-size: 16px;
    }
}