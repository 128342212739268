.contenedorInicio {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    background-color: #82673B;
}

.cuerpo-Servicios {
    background-color: white;
}

body {
    overflow-x: hidden;
}

.EspacioBlancoServicios {
    display: flex;
    width: 100%;
    height: 100px;
    background-color: white;
}

.fixed-header-Servicios {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vh;
    z-index: 5;

}

.outer-div-Footer-Servicios {
    background-color: transparent;
    width: 100vw;
    height: 15vh;

}


.ServiciosDisponibles {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    justify-content: center;
}

.ServiciosDisponibles .Titulo {
    display: flex;
    width: 60vw;
    height: auto;
    text-align: start;
    color: black;
    font-size: 2em;
    font-family: 'Lato';

}

.ServiciosDisponibles .FilasServicios {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom: 1%;
}


.ServiciosDisponibles .FilasServicios .BoxServicio {
    display: flex;
    align-items: center;
    background-color: #F2F2F2;
    width: 80%; 
    height: 30%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    margin: 1%;
    box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
    overflow: hidden;  /* Evita el desbordamiento */
    min-height: 250px;
    flex-direction: row;
}

.ServiciosDisponibles .FilasServicios .BoxServicio img {
    width: 60%;
    height: 100%;
    object-fit: cover;
}

.ServiciosDisponibles .FilasServicios .BoxServicio .BoxTexto {
    width: 30%;
    height: 100%;
    padding: 0 15px;
    display: left;
    min-width: 30%;
    flex-grow: 1;
    box-sizing: border-box;
    overflow: hidden;
    
}

.ServiciosDisponibles .FilasServicios .BoxServicio .BoxTexto .Titulo {
    /* display: flex; */
    width: 50%;
    height: 20%;
    text-align: start;
    font-size: 1em;
    font-family: 'Lato';
    color: black;
    margin-top: 10%;
}

.ServiciosDisponibles .FilasServicios .BoxServicio .BoxTexto .Descripcion {
    width: 100%;
    height: 20%;
    font-size: 0.8em;
    font-family: 'Karla';
    color: black;
    margin-top: 1%;
    margin-bottom: 2%;
    text-align: left;

}

.ServiciosDisponibles .FilasServicios .BoxServicio:hover {
    transform: scale(1.01);
    transition: transform 0.3s ease-in-out;
}


@media (max-width: 1000px) {
    .ServiciosDisponibles .FilasServicios .BoxServicio {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        padding: 1%;
        height: 100%;
    }

    .ServiciosDisponibles .Titulo {
        width: 90%;
        text-align: center;
        justify-content: center;
        margin: 0;
    }

    .ServiciosDisponibles .FilasServicios .BoxServicio img {
        height: 40%;
        margin: 1%;
    }

    .ServiciosDisponibles .FilasServicios .BoxServicio .BoxTexto {
        margin: 0;
        padding: 0;
        display: flex;
        width: 100%;
        height: 50%;
        flex-direction: column;
        align-items: center;
    }
    .ServiciosDisponibles .FilasServicios .BoxServicio .BoxTexto .Titulo {
        margin: 1%;
        width: 90%;
        height: 50%; /* Mayor espacio */
    }
    .ServiciosDisponibles .FilasServicios .BoxServicio .BoxTexto .Descripcion {
        width: 90%;
        height: 50%; /* Mayor espacio */
    }
}