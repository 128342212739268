.containerCabañas {
    position: relative;
    width: 100vw;
    height: 384px;

}

.descriptionCabañas {
    position: absolute;
    width: 100%;
    height: 384px;
}


.descriptionCabañas .imageContainer img {
    width: 100vw;
    height: 384px;
    object-fit: cover;
}

.imageContainer .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 59, 36, 0.3);
    /* Color de la capa con opacidad */
}

.descriptionCabañas p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    height: auto;
    text-align: center;
    line-height: normal;
    font-family: "Lato";
    font-size: 1.5em;
    font-stretch: normal;
    font-style: Regular;
    text-decoration: none;
}

.precio {
    font-size: 18px; /* Tamaño deseado */
    font-weight: normal;
}